export default function Index() {
  return (
    <h1>
      Welcome to Zenchef booking widget !
      <style global jsx>
        {`
          body {
            background-color: white !important;
          }
        `}
      </style>
    </h1>
  )
}
